<template>
  <app-page-layout :title="$t('hr.health.disease_detail')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <v-row>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.name") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2"> {{ $helpers.get(disease, "name") || "-" }}</span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.category") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2"> {{ $helpers.get(disease, "category.name") || "-" }}</span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.importance") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $t(`hr.health.importance_enum.${$helpers.get(disease, "importance")}`) }}</span
          >
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.from") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2"> {{ $helpers.get(disease, "fromDate") | formattedDate }}</span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.to") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2"> {{ $helpers.get(disease, "toDate") | formattedDate }}</span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.medicines") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{
              $helpers
                .get(disease, "medicines", [])
                .map(({ name }) => name)
                .join(", ") || "-"
            }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.attachment") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <file-download-button
            v-else-if="$helpers.get(disease, 'attachment')"
            :url="$helpers.get(disease, 'attachment')"
          />
          <span v-else class="grey--text body-2">
            {{ "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <span class="tertiary--text font-weight-bold">{{ $t("hr.health.description") }}</span>
        </v-col>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(disease, "description") || "-" }}
          </span>
        </v-col>
      </v-row>
    </template>
  </app-page-layout>
</template>

<script>
  import { DISEASE_DETAIL } from "../../query";

  export default {
    name: "DiseaseDetail",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    props: {
      id: [String, Number]
    },
    data: () => ({
      loading: false,
      disease: null
    }),
    watch: {
      id: {
        handler() {
          this.fetchSelectedItemById();
        },
        immediate: true
      }
    },
    methods: {
      leavePage() {
        this.$emit("closed:detail");
        this.$router.replace({ name: "health" });
      },
      async fetchSelectedItemById() {
        this.loading = true;
        await this.$apollo
          .query({
            query: DISEASE_DETAIL,
            variables: {
              id: this.$helpers.decodeId(this.id)
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.disease = data.disease;
              this.$emit("detail-loaded:success", data.disease);
            }
          })
          .catch(() => {
            this.$emit("detail-loaded:error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
